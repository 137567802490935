import React, { useState } from 'react';
import { navigate } from 'gatsby';
import * as styles from './ProductCard.module.css';

const ProductCard = (props) => {
  const {
    image,
    imageAlt,
    name,
    navPath,
    height = 580,
  } = props;

  const [isHovering, setIsHovering] = useState(false);

  const handleRouteToProduct = () => {
    navigate(navPath);
  };

  const handleOnMouseOver = () => {
    setIsHovering(true);
  };

  const handleOnMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div className={`${styles.root} ${styles.className} ${isHovering ? styles.hovering : ''}`}>
      <div role={'presentation'} onClick={() => handleRouteToProduct()} onMouseOver={() => handleOnMouseOver()}
        onMouseOut={() => handleOnMouseOut()}
        className={`${styles.imageContainer}`}>
        <img style={{ height: `${height}px` }} src={image} alt={imageAlt}></img>
      </div>
      <div className={styles.detailsContainer}>
        <span className={styles.productName}>{name}</span>
      </div>
    </div>
  );
};

export default ProductCard;
