import * as React from 'react';

import Container from '../components/Container';
import Hero from '../components/Hero';
import Layout from '../components/Layout/Layout';
import ProductCardGrid from '../components/ProductCardGrid';
import Contact from '../components/Contact';

import { generateMockProductData } from '../helpers/mock';

import * as styles from './index.module.css';
import "../components/Layout/Globals.css";
import { navigate } from 'gatsby';

const IndexPage = () => {
  const newArrivals = generateMockProductData(5, 'service');

  return (
    <Layout disablePaddingBottom>
      {/* Hero Container */}
      <div data-scroll-section>
        <Hero
          images={['/banner1.jpeg', '/banner_2.jpeg', '/banner3.jpeg', '/banner4.jpeg']}
        />
      </div>

      <div id="welcome" data-scroll-section>
        <Container>
            <div className={`${styles.grid5050} ${styles.welcomeContainer}`}>
              <div data-fade-in-section>
                <h1 >Welcome to<br></br>Pristine Pool & Spa</h1>
                <p class={styles.sectionDescription}>Serving Sonoma, Marin, Napa, Lake & Mendocino Counties</p>
              </div>
              <p data-fade-in-section>
                Pristine Pool & Spa has been in the business of building and remodeling beautiful, quality pools at an affordable price for more than 40 years. As a family-owned and operated business, we serve the North Bay with a commitment to excellence in design and construction. You will receive personal assistance from the initial planning stage of the project to completion, all from the same professionals.
                <br></br>
                <br></br>
                <ul className="bullets">
                  <li>Family owned & operated</li>
                  <li>40+ years in the swimming pool industry</li>
                  <li>Free on-site estimates</li>
                  <li>Guaranteed quality installation</li>
                </ul>
              </p>
            </div>
        </Container>
      </div>

      <div id="services" className={styles.servicesContainer}>
        <Container data-scroll data-scroll-speed="2">
          <div>
            <h4 data-fade-in-section className={styles.servicesHeader}>Our Services</h4>
            <p>
              We specialize in vinyl liner replacement, remodeling existing pools and new installation of inground vinyl, above-ground and exercise pools.
              Our experienced team will provide you with quality workmanship and professional service. We look forward to working with you to help create your personalized backyard oasis.
            </p>
          </div>
          <div className={styles.servicesGrid}>
            <ProductCardGrid
              spacing={true}
              showSlider
              height={320}
              columns={2}
              data={newArrivals}
              />
          </div>
        </Container>
      </div>
      <div className={styles.contactContainer}>
        <Container>
          <div>
            <Contact></Contact>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default IndexPage;
