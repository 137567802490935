import React, { useEffect } from 'react';
import * as styles from './Hero.module.css';

const Hero = (props) => {
  const {
    images,
  } = props;

  const randomCompare = (a, b) => {  
    return 0.5 - Math.random();
  };
  var sortedImages = images?.sort(randomCompare);   

  useEffect(() => {
    const domImages = document.getElementsByClassName(styles.image);
    let currImageIndex = 0;
    domImages.item(currImageIndex).classList.add(styles.active);
    const getNextImage = () => {
      const currImage = domImages && domImages.length > currImageIndex ? domImages.item(currImageIndex) : null;
      if (!!currImage) {
        currImage.classList.remove(styles.active);
      }

      const nextImageIndex = (currImageIndex + 1) % images.length;
      const nextImage = domImages && domImages.length > nextImageIndex ? domImages.item(nextImageIndex) : null;
      if (!!nextImage) {
        nextImage.classList.add(styles.active);
        currImageIndex = nextImageIndex;
      }
    };
    const nextImageInterval = setInterval(getNextImage, 7000);
    return () => clearInterval(nextImageInterval);
  });

  return (
    <div className={styles.root}>
      {sortedImages?.map((image, index) => {
        return (
          <div key={index} className={`${styles.image}`} style={{ backgroundImage: `url(${image})` }}>
          </div>
        );
      })}
    </div>
  );
};

export default Hero;
